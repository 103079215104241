.nin-color {
  background-color: #0071ce !important;
  color: #ffffff;
}

.nin-color .navbar-burger {
  color: #ffffff;
}

.hero.is-info {
  background-color: #0071ce !important;
}
